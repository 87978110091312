import React from "react";

function Icon({...restProps}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="98"
      height="98"
      viewBox="0 0 98 98"
      {...restProps}
    >
      <defs>
        <linearGradient id="z7msn3fdma" x1="100%" x2="0%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#4202A9"></stop>
          <stop offset="100%" stopColor="#36008A"></stop>
        </linearGradient>
        <linearGradient id="uozxcjb3pb" x1="100%" x2="0%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#FF7F6E"></stop>
          <stop offset="100%" stopColor="#FE4F55"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle cx="49" cy="49" r="49" fill="url(#z7msn3fdma)"></circle>
        <circle cx="49" cy="49" r="18" fill="url(#uozxcjb3pb)"></circle>
      </g>
    </svg>
  );
}

export default Icon;
