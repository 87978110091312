import React from "react";

function Icon({...restProps}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="16"
      viewBox="0 0 36 16"
      {...restProps}
    >
      <path
        fill="currentColor"
        d="M28.007.565l-.85.847 5.992 5.974H.4v1.196h32.749l-5.992 5.974.85.847 7.442-7.419z"
      ></path>
    </svg>
  );
}

export default Icon;
