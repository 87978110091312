import { h } from 'preact'

import Arrow from '../../assets/images/Arrow'
import Splash from '../../assets/images/Splash'
import How from '../../assets/images/How'
import What from '../../assets/images/What'
import Who from '../../assets/images/Who'
import diploma from '../../assets/images/diploma.webp'

import style from './style.sass'

const Home = () => (
	<>
		<div class={style.pageHeader}>
			<div class={style.svg_bg}></div>
			<div class={style.container}>
				<div class={style.home}>
					<h1 class={style.title}>
						<span>Hedge Fund</span> <span>That's Simplified</span> <span>for You</span>
					</h1>
					<a class={style.link} target="_blank" href='https://www.linkedin.com/company/hedgefun-d/'>
						<Arrow class={style.link_icon}/>
						<span>Linkedin</span>
					</a>
				</div>
				<img class={style.diploma} src={diploma} alt="Certificate"/>
			</div>
		</div>
		<div class={style.about}>
			<div class={style.about_container}>
				<Splash class={style.about_svg} />
				<div class={style.about_textBox}>
					<h2 class={style.about_title}>HedgeFun(d) Is a Digital Hedge Fund Built to Help You</h2>
					<p class={style.about_text}>HedgeFun(d) is a full-cycle independent Hedge Fund that combines cutting-edge technology and a dedicated team of professionals for a modern, yet personal experience.</p>
				</div>
			</div>
		</div>
		<div class={style.features}>
			<div class={style.features_container}>
				<div>
					<Who class={style.features_icon} />
					<h3 class={style.features_title}>Who we are</h3>
					<p class={style.features_text}>Ideas are not enough in the pursuit of successful investments. Our team members share successful commitment to pursuing superior returns, applying their talents to fundamental research and advanced quantitative analysis to bring their insights to market.  </p>
				</div>
				<div>
					<How class={style.features_icon} />
					<h3 class={style.features_title}>How we do it</h3>
					<p class={style.features_text}>Operating in a culture of excellence, and leveraging long-term relationships with thousands of companies and institutions, we move swiftly and deliberately to capitalize on opportunities having the greatest potential.</p>
				</div>
				<div>
					<What class={style.features_icon} />
					<h3 class={style.features_title}>What we do</h3>
					<p class={style.features_text}>HedgeFun(d) strategies focus on all major asset classes across the world’s capital markets. Within a broad range of investment strategies, we deploy our capital with the objective of generating market-leading investment returns.</p>
				</div>
			</div>
		</div>
		<div class={style.banner}>
			<div class={style.banner_block}>
				<h2 class={style.banner_title}>We build. We improve. We perfect.</h2>
			</div>
		</div>
	</>
);

export default Home
