import React from "react";

function Icon({...restProps}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="98"
      height="98"
      viewBox="0 0 98 98"
      {...restProps}
    >
      <defs>
        <linearGradient id="t9auyx665a" x1="100%" x2="0%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#4C00FE"></stop>
          <stop offset="100%" stopColor="#051CBE"></stop>
        </linearGradient>
        <linearGradient id="pzox58utrb" x1="100%" x2="0%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#FF61FC"></stop>
          <stop offset="100%" stopColor="#FF21E3"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="url(#t9auyx665a)" d="M0 0l98 98H0z"></path>
        <path
          fill="url(#pzox58utrb)"
          d="M0 0c53.583 0 97.121 43.003 97.987 96.38L98 98H49.23l.003-.232c0-26.792-21.502-48.561-48.19-48.994l-.81-.006H0V0z">
        </path>
      </g>
    </svg>
  );
}

export default Icon;
