import React from "react";

function Icon({...restProps}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="162"
      height="98"
      viewBox="0 0 162 98"
      {...restProps}
    >
      <defs>
        <linearGradient
          id="y4p71myv9a"
          x1="65.02%"
          x2="34.98%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#96F4FE"></stop>
          <stop offset="100%" stopColor="#00E9FF"></stop>
        </linearGradient>
        <linearGradient
          id="0k9efg32ib"
          x1="65.02%"
          x2="34.98%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#FEBC24"></stop>
          <stop offset="100%" stopColor="#FF9738"></stop>
        </linearGradient>
        <linearGradient
          id="829011pzgc"
          x1="65.02%"
          x2="34.98%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#FF7F6E"></stop>
          <stop offset="100%" stopColor="#FE4F55"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="url(#y4p71myv9a)"
          d="M107.423 0v97.997l53.712-48.999z"
        ></path>
        <path
          fill="url(#0k9efg32ib)"
          d="M53.712 0v97.997l53.711-48.999z"
        ></path>
        <path fill="url(#829011pzgc)" d="M0 0v97.997l53.712-48.999z"></path>
      </g>
    </svg>
  );
}

export default Icon;
